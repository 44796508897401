import React from "react"
import Layout from "../components/Layout"
import Header from "../components/shared/Header"
import CTAblockCompo from "../components/shared/CTAblock"
import { graphql, Link } from "gatsby"
import PressBlockShared from "../components/shared/Press_item"
// import PaypalBTN from "../components/shared/paypalBtn"

const HtmlBlock = ({ data }) => (
  <div
    className="text_wrapper"
    dangerouslySetInnerHTML={{
      __html: data,
    }}
  />
)

const Testomonials = ({ data }) => (
  <section className="testimomals_wrapper">
    <div className="layout">
      {data.map((item, i) => (
        <div className="testimonals_wrp" key={`test-${i}`}>
          <div className="frame">
            <img src={item.avatar.url} alt="avatar" />
          </div>
          <p className="name">{item.name.text}</p>
          <p className="test">{item.text.text}</p>
        </div>
      ))}
    </div>
  </section>
)

const ProgramItem = ({ data }) => {
  const { allPrismicBlogPost, allPrismicProgramItemPage } = data

  const {
    title_program,
    intro_text,
    why,
    why_text,
    what,
    what_text,
    price,
    shipped,
    launched,
    testimonials,
    cover_img,
    paypal_link,
  } = allPrismicProgramItemPage.edges[0].node.data

  console.log("TCL: ProgramItem -> launched", launched)
  return (
    <div className="program_template_page">
      <Layout>
        <Header title="Program" subtitle={title_program.text} />
        <section className="bodyText">
          <div className="layout strech_layout">
            <p className="link_wrapper">
              <Link to="/process">Back to all programs</Link>
            </p>
            <div className="block">
              <img src={cover_img.url} alt="dasf" />
              <h3>
                {title_program.text}
                {launched === "no" ? (
                  <span className="launching_soon">Launching soon</span>
                ) : null}
              </h3>

              <HtmlBlock data={intro_text.html} />
            </div>
            <div className="block">
              <h3>{why.text}</h3>
              <HtmlBlock data={why_text.html} />
            </div>
            <div className="block">
              <h3>{what.text}</h3>
              <HtmlBlock data={what_text.html} />
            </div>
          </div>
        </section>
        <Testomonials data={testimonials} />
        <section className="program_wrapper_item">
          <div className="layout">
            <div className="programs_item">
              <div className="item_program">
                <h4>{title_program.text}</h4>
                <div className="info_wrap">
                  <div className="price">
                    <p className="oneMoreTime">One time purchase </p>
                    <p className="price_tag">${price}</p>
                    {shipped === "yes" ? (
                      <p className="ship"> Shipped</p>
                    ) : null}
                  </div>
                  <div className="btn_wrapper">
                    <button>
                      <a href={paypal_link.url} target="blank">
                        BUY NOW
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PressBlockShared data={allPrismicBlogPost.edges} />
        <CTAblockCompo />
      </Layout>
    </div>
  )
}

export default ProgramItem

export const query = graphql`
  query($uid: String) {
    allPrismicBlogPost(limit: 2) {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
            texto {
              text
            }
            cover_image {
              url
            }
            media_link {
              url
            }
          }
        }
      }
    }
    allPrismicProgramItemPage(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          id
          data {
            title_program {
              html
              text
            }
            intro_text {
              html
            }
            why {
              text
            }
            why_text {
              html
            }
            what {
              text
            }
            what_text {
              html
            }
            launched
            price
            shipped
            paypal_link {
              url
            }
            cover_img {
              url
            }
            testimonials {
              avatar {
                url
              }
              name {
                text
              }
              text {
                html
                text
              }
            }
          }
        }
      }
    }
  }
`
